document.addEventListener("DOMContentLoaded", () => {
  // Lang switcher
  const langBtn = document.querySelector(".lang-btn");
  const langMenu = document.querySelector(".lang-menu");
  const langMenuButtons = langMenu.querySelectorAll("button");

  langBtn.addEventListener("click", () => {
    const isMenuOpen = langMenu.style.display === "block";
    langMenu.style.display = isMenuOpen ? "none" : "block";
    langBtn.classList.toggle("active", !isMenuOpen);
  });

  langMenuButtons.forEach((button) => {
    button.addEventListener("click", (event) => {
      const selectedLang = event.target.getAttribute("data-lang");
      langBtn.textContent = selectedLang.toUpperCase();
      langMenu.style.display = "none";
      langBtn.classList.remove("active");
    });
  });

  document.addEventListener("click", (event) => {
    if (!langBtn.contains(event.target) && !langMenu.contains(event.target)) {
      langMenu.style.display = "none";
      langBtn.classList.remove("active");
    }
  });

  // Show/Hide content on btn click
  const toggleButton = document.querySelector(".content-toggle-btn a");
  const hiddenContent = document.querySelector(".hidden-content-block");

  toggleButton.addEventListener("click", function (event) {
    event.preventDefault();
    hiddenContent.classList.toggle("show");

    // Adjust the max-height based on the content height
    if (hiddenContent.classList.contains("show")) {
      hiddenContent.style.maxHeight = hiddenContent.scrollHeight + "px";
    } else {
      hiddenContent.style.maxHeight = "0";
    }
  });

  // Video Modal popup
  // var modal = document.getElementById("videoModal");
  // var openBtn = document.querySelector(".video-play-button a");
  // var closeBtn = document.getElementsByClassName("close")[0];

  // var videoFrame = document.getElementById("videoFrame");

  // openBtn.onclick = function (event) {
  //   event.preventDefault();
  //   modal.style.display = "block";
  //   videoFrame.src = "https://www.youtube.com/embed/K4TOrB7at0Y?si=k6kab0Zvo3LFQHQD";
  // };

  // closeBtn.onclick = function () {
  //   modal.style.display = "none";
  //   videoFrame.src = "";
  // };

  // window.onclick = function (event) {
  //   if (event.target == modal) {
  //     modal.style.display = "none";
  //     videoFrame.src = "";
  //   }
  // };
});
